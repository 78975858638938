import React, { useEffect, useState } from "react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import { Code } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";

const CodesContainer: React.FC<{}> = () => {
  const { t } = useTranslation("codes");
  const [codes, setCodes] = useState<Code[]>([]);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "codes" });
    getNewCodeList();
  }, []);

  const getNewCodeList = async () => {
    let codeListRes = await tryCatch(api.subscription.getCodesList());
    if (codeListRes.error) {
      console.log("Error fetching codes: ", codeListRes.error);
    } else {
      if (
        codeListRes.result &&
        codeListRes.result.data &&
        codeListRes.result.data.codes
      ) {
        let displayedCodes: Code[] = [];
        codeListRes.result.data.codes.forEach((code) => {
          if (code.type === "NordVPN 1 Year") {
            displayedCodes.push(code);
          }
        });
        setCodes(displayedCodes);
      }
    }
  };

  return (
    <div className="py-0 px-8 mx-[-32px]">
      <Title
        title={t("title")}
        subtitle={`${codes.length} ${t("subtitle")}`}
        className="mt-7"
      />
      {codes.length > 0 && (
        <ul className="list-none pl-0">
          <div
            style={{
              display: "flex",
              padding: "20px 0px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "45%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("code")}
              </div>
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("type")}
              </div>
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("purchasedOn")}
              </div>
            </div>
            {codes.map((code) => (
              <CodeRow code={code} />
            ))}
          </div>
        </ul>
      )}
      <NavigationLink to="/account/purchase" title={t("purchaseCode")} />
    </div>
  );
};

interface CodeRowProps {
  code: Code;
}

const CodeRow: React.FC<CodeRowProps> = ({ code }) => {
  const { t } = useTranslation("codes");

  return (
    <div
      style={{ display: "flex" }}
      className={"text-gray-500"}
      key={`${code.code}_${code.consumed_on}`}
    >
      <div
        style={{
          width: "45%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{code.code}</p>
      </div>
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{code.type}</p>
      </div>
      <div
        style={{
          width: "30%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">
          {new Date(code.consumed_on).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default CodesContainer;
