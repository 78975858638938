
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
                                         
  "error": {
    "title": "Error"
  },
                                     
  "errors": {
    "sessionExpiredMessage": "Your session has expired. Please login again.",
    "deleteProfileMessage": "Error deleting profile. Please try again or contact support",
    "setBedTimeDelayMessage": "Error setting delay for bedtime",
    "speedTestMessage": "Speed test unsuccessful. Please try again later or contact support",
    "activationMessage": "Please enter your code manually",
    "setBedTimeMessage": "Error setting bedtime",
    "loadingDeviceImagesMessage": "Error loading device images",
    "wifiConfigError": "Error configuring wifi"
  },
  "success": {
    "deleteProfileMessage": "Your profile has been deleted successfully.",
    "deleteProfileTitle": "Profile deleted",
    "setBedTimeDelayTitle": "Bedtime delay set",
    "bedTimeRemoved": "Bedtime delay was reset successfully",
    "deviceUpdatedMessage": "Device updated successfully",
    "deviceUpdatedTitle": "Success",
    "dataCapTitle": "Data Cap set",
    "dataCapMessage": "Data Cap was set successfully",
    "setBedTimeTitle": "Success",
    "setBedTimeMessage": "Bedtime was set successfully",
    "wifiConfigured": "Wifi configured successfully",
    "speedTestMessage": "Speed test was successful"
  },

  "unsavedModal": {
    "title": "Unsaved Changes",
    "description": "Do you really want to leave without saving? Any unsaved changes will be lost.",
    "ok": "Leave",
    "cancel": "cancel"
  },
  "menu": {
    "account": "Account",
    "help": "Help",
    "logout": "Logout"
  },
  "deleteProfileConfirmationModal": {
    "title": "Delete Profile",
    "description": "Do you really want to delete this profile?",
    "ok": "Delete",
    "cancel": "Cancel"
  },
  "profileHasDevicesModal": {
    "title": "Delete Profile",
    "description": "This profile has devices associated with it. You cannot delete it",
    "ok": "Ok",
    "cancel": "Cancel"
  },
  "footer": {
    "text": "2023 Privacy Hero, Inc. All rights reserved."
  }
}
