import Button from "components/Button";
import Title from "components/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";

const AllSetContainer: React.FC = () => {
  const { t } = useTranslation("onboarding");
  const history = useHistory();
  return (
    <PageLayout>
      <OnboardingSteps stage="allSet" />
      <Title title={t("allSet.title")} className="mb-4" />
      <Button
        type="button"
        onClick={() => {
          history.push("/");
        }}
        className="mt-2"
      >
        {t("allSet.goHome")}
      </Button>
    </PageLayout>
  );
};
export default AllSetContainer;
