export const VPN_PROVIDERS = {
  phero: {
    name: "Privacy Hero",
    value: "phero",
  },
  nordvpn: {
    name: "NordVPN",
    value: "nordvpn",
  },
  surfshark: {
    name: "SurfShark",
    value: "surfshark",
  },
};
