import React, { useState, useEffect, useMemo } from "react";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import appConfig from "../../AppConfig";
import http from "axios";
import { PageLayout } from "../../layouts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";
import NavigationLink from "components/NavigationLink";
import { checkmarkCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import ErrorMessage from "components/ErrorMessage";
import InformationMessage from "components/InformationMessage";
import PheroImage from "assets/images/phero-image.png";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { emailRegex } from "../../utils/regexValidators";

const validateEmail = (email: string): boolean =>
  emailRegex.test(email.toLowerCase());

const PasswordRecoveryComponent: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { search } = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation();
  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "password_recovery" });
  }, []);
  useEffect(() => {
    dispatch.adapter.getClaimedAdapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isCreateAccount = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get("newCustomer");
  }, [search]);
  const { title, buttonText, successMessage, queryParams } = useMemo(() => {
    return isCreateAccount
      ? {
          title: t("createAccount:title"),
          buttonText: t("createAccount:pressable.createAccount"),
          successMessage: t("createAccount:successMessage"),
          queryParams: "?newCustomer=true",
        }
      : {
          title: t("createAccount:resetPasswordTitle"),
          buttonText: t("createAccount:pressable.resetPassword"),
          successMessage: t("createAccount:resetPasswordSuccessMessage"),
          queryParams: "",
        };
  }, [isCreateAccount, t]);
  const submit = (form?: React.FormEvent<HTMLFormElement>) => {
    if (form) {
      form.preventDefault();
    }
    firebase.analytics().logEvent("reset_password", {});
    FirebaseAnalytics.logEvent("reset_password", {});
    http
      .post<{ msg: string }>("/v1/auth/newcustomer", {
        email,
        callback: `${appConfig.DOMAIN}passwordReset${queryParams}`,
      })
      .then(() => setSubmitted(true))
      .catch((e) => {
        setError(e);
      });
  };
  // const query = new URLSearchParams(search);

  const { claimedAdapter } = useSelector((state: RootState) => ({
    claimedAdapter: state.adapter.claimedAdapter,
  }));

  const claimedAdapterNotice = useMemo(() => {
    if (claimedAdapter && isCreateAccount) {
      let text = "";
      if (claimedAdapter.owner) {
        text = `This network already has a Privacy Hero router under the email ${
          claimedAdapter.owner
        }. ${
          !claimedAdapter.online
            ? "It's not currently connected to the internet."
            : ""
        }`;
      } else {
        text = `It seems You have a fresh Privacy Hero router. Please create an account or login to set it up.`;
      }
      return <InformationMessage className="mb-2" text={text} />;
    }
    return null;
  }, [claimedAdapter, isCreateAccount]);

  return (
    <PageLayout testId="password-recovery">
      {!submitted && (
        <div>
          <InformationMessage
            text={[
              `Congratulations on receiving your Privacy Hero router!`,
              <br />,
              `Please create your account below to start the set-up process:`,
            ]}
            className="mb-4"
          />
          {claimedAdapterNotice}
          <Title title={title} className="mb-4" />
          <form onSubmit={(e) => submit(e)}>
            <Input
              id="email"
              type="email"
              label={t("createAccount:email.label")}
              placeholder={t("createAccount:email.placeholder")}
              inputProps={{
                value: email,
                onChange: (e: any) => setEmail(e.target.value),
              }}
            />
            <Button
              className="mt-2"
              data-testid="reset"
              type="submit"
              disabled={!validateEmail(email)}
            >
              {buttonText}
            </Button>
          </form>
          <ErrorMessage
            error={error}
            fallback={
              isCreateAccount
                ? t("createAccount:resetPasswordFallbackError")
                : t("createAccount:fallbackError")
            }
          />
        </div>
      )}
      {submitted && (
        <div>
          <div className="module-large">
            <Title
              data-testid="thanks"
              title={t("createAccount:thanks")}
              className="mb-4"
            />
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <IonIcon
                    icon={checkmarkCircle}
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    {successMessage}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <NavigationLink to="/" title={t("createAccount:pressable.login")} />
      <img src={PheroImage} alt="phero router" />
    </PageLayout>
  );
};

export default PasswordRecoveryComponent;
