import { IonToast } from "@ionic/react";
import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { chevronDown } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import PageLayout from "../layouts/PageLayout";
import { ListRow, Toggle } from "../components";
import {
  AdapterGetServiceType,
  VPNConns,
  AdapterListItem,
  UIServiceState,
} from "../model";

//TODO uncommend when #5819 is fixed
// import AppConfig from "../AppConfig";
// import NavigationLink from "components/NavigationLink";

import { createPollAndRefresh } from "../utils";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import { useSetState } from "ahooks";
import Input, { PasswordInput } from "components/Input";
import vest, { enforce, test } from "vest";

import i18n from "i18next";
import { hostnameRegex } from "../utils/regexValidators";

import { useForm } from "react-hook-form";
import { VPNProviderRequirements } from "api/vpn";
import Button from "components/Button";
import { useHistory } from "react-router-dom";

import { VPN_PROVIDERS } from "../constants";

interface Props {
  adapterId: string;
  pheroVpn: boolean;
}
type ReadOnlyConfigService = "online" | "Subscribed";
type ConfigServices = Exclude<AdapterGetServiceType, ReadOnlyConfigService>;
type ServiceStatus = {
  [key in ConfigServices]: UIServiceState | undefined;
};

//TODO, unused code, refactor in V2 of #5833 ticket
// interface ProviderSelections {
//   [key: string]: any;
// }

//TODO, unused function, refactor in V2 of #5833 ticket
// function newProviderSelection(
//   field_name: string,
//   value: string,
//   previous: ProviderSelections
// ) {
//   if (previous[field_name] !== undefined) {
//     previous[field_name] = value;
//     return previous;
//   }
//   var newProviderSelection: ProviderSelections = {};
//   newProviderSelection[field_name] = value;
//   return Object.assign(newProviderSelection, previous);
// }

//TODO, unused function, refactor in V2 of #5833 ticket
// function getProviderSelectionValue(
//   field_name: string,
//   providerSelections: ProviderSelections
// ) {
//   if (providerSelections[field_name] !== undefined) {
//     return providerSelections[field_name];
//   } else {
//     return "";
//   }
// }

const validationSuiteVpnHostname = vest.create((data: any) => {
  test(
    "providerHostname",
    i18n.t("securitySettings:vpnSettings.validationErrors.vpnHostname"),
    () => {
      enforce(data.providerHostname).matches(hostnameRegex);
    }
  );
  test("providerHostname", i18n.t("editNetwork:wifi.required"), () => {
    enforce(data.providerHostname).isNotEmpty();
  });
});

const Settings: React.FC<Props> = ({ adapterId, pheroVpn }) => {
  const { adapterDetails } = useSelector((state: RootState) => ({
    adapterDetails: state.adapter.adapter!,
  }));
  const [toastMessage, setToastMessage] = useState<string>("");

  const history = useHistory();

  //TODO, unused code, refactor in V2 of #5833 ticket
  // const [providerSelections, setProviderSelections] = useState<object>({});

  const [serviceStatus, setServiceStatus] = useState<ServiceStatus>({
    adblocking: undefined,
    malware: undefined,
    vpn: undefined,
    "vpn-killswitch": undefined,
    streamrelocation: undefined,
    wps: undefined,
    upnp: undefined,
  });

  const updateStatus = (
    key: AdapterGetServiceType,
    state?: UIServiceState,
    reset?: boolean
  ) => {
    setServiceStatus({ ...serviceStatus, [key]: state });
  };

  const dispatch = useDispatch<Dispatch>();
  const loadAdapter = () => dispatch.adapter.getAdapter(adapterId);
  const [state, setState] = useSetState<{ loading: boolean; error: string }>({
    loading: true,
    error: "",
  });

  const providerData = useSelector(
    (state: RootState) => state.vpn.adapterVPNProviderMap
  );

  const [uiStatus, setUiStatus] = useState<UIServiceState>();

  const [connError, setConnError] = useState<boolean>(false);
  const [VPNConnStatus, setVPNConnStatus] = useState<VPNConns>();
  const [providers, setProviders] = useState(providerData);
  const [provider, setProvider] = useState<string>("phero");

  const [providerDSPName, setProviderDSPName] = useState<string>("PrivacyHero");

  const [providerVpnUsername, setProviderVpnUsername] = useState<string>("");
  const [providerVpnPassword, setProviderVpnPassword] = useState<string>("");
  const [
    providerVpnServerHostname,
    setProviderVpnServerHostname,
  ] = useState<string>("");
  const [
    providerVpnServerHostnameHasErrors,
    setProviderVpnServerHostnameHasErrors,
  ] = useState<any>({});
  const [selectedProviderCreds, setSelectedProviderCreds] = useState<any>();
  const [
    selectedProviderCredsHasOptionalFields,
    setSelectedProviderCredsHasOptionalFields,
  ] = useState<boolean>(false);
  const [
    providerConfigAdvancedSectionIsActive,
    setProviderConfigAdvancedSectionIsActive,
  ] = useState<boolean>(false);

  const [providerSelectionState, setSelectionState] = useState<string>(
    "provider_status"
  ); //provider_connecting  provider_status
  const current_vpn_provider = adapterDetails.settings.vpn;

  const currentUsedProviderVpnServerHostname =
    adapterDetails.settings.vpn?.[0]?.server_hostname;

  const current_connections = adapterDetails.status?.vpn_connections;
  const lastUsedProviderVpnUsername = current_vpn_provider?.[0]?.username || "";
  const lastUsedProviderVpnServerHostname =
    current_vpn_provider?.[0]?.server_hostname || "";
  const lastUsedProviderVpnProvider = current_vpn_provider?.[0]?.provider;

  useMemo(() => {
    const newSelectionProviderMeta = providerData.find(
      (prov) => prov.provider === provider
    );

    if (newSelectionProviderMeta) {
      //If provider has configuration fields (inputs), transfrom from an Array to Object so it's more convenient to read these values in JSX
      if (newSelectionProviderMeta.creds.length > 0) {
        const selectedProviderCreds = newSelectionProviderMeta.creds.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.field]: cur,
          }),
          {}
        );

        setSelectedProviderCreds(selectedProviderCreds);
      } else {
        setSelectedProviderCreds({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useMemo(() => {
    if (typeof selectedProviderCreds !== "undefined") {
      setSelectedProviderCredsHasOptionalFields(
        Boolean(
          Object.values(selectedProviderCreds).filter(
            (providerCreds: any) => providerCreds.optional
          ).length
        )
      );
    }
  }, [selectedProviderCreds]);

  useMemo(() => {
    if (!providerConfigAdvancedSectionIsActive) {
      setProviderVpnServerHostname("");
      setProviderVpnServerHostnameHasErrors({});
    }
  }, [providerConfigAdvancedSectionIsActive]);

  useMemo(() => {
    if (currentUsedProviderVpnServerHostname) {
      setProviderConfigAdvancedSectionIsActive(true);
    }
  }, [currentUsedProviderVpnServerHostname]);

  const vpnConnectionConfigValuesMap = {
    username: {
      value: providerVpnUsername,
      setValue: setProviderVpnUsername,
    },
    password: {
      value: providerVpnPassword,
      setValue: setProviderVpnPassword,
    },
    server_hostname: {
      value: providerVpnServerHostname,
      setValue: setProviderVpnServerHostname,
    },
  };

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "security_settings" });
  }, []);

  useEffect(() => {
    setState({ loading: true });
    Promise.resolve(dispatch.vpn.getVPNProviders())
      .then((vpn_providers) => {
        setState({ loading: false });
        setProviders(vpn_providers);
        if (current_vpn_provider !== undefined) {
          setProvider(current_vpn_provider[0].provider);
          setProviderVpnUsername(lastUsedProviderVpnUsername);
          setProviderVpnServerHostname(lastUsedProviderVpnServerHostname);
          for (var provider_idx in vpn_providers) {
            if (
              vpn_providers[provider_idx].provider ===
              current_vpn_provider[0].provider
            ) {
              setProviderDSPName(vpn_providers[provider_idx].display_name);
            }
          }
          if (current_connections !== undefined) {
            setVPNConnStatus(current_connections[0]);
          }
        } else {
          setProvider(VPN_PROVIDERS.phero.value);
          setProviderDSPName(VPN_PROVIDERS.phero.name);
        }
      })
      .catch((error) => {
        console.log("err", error);
        setState({
          loading: false,
          error: `Error loading vpn provider configuration`,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerData]);

  useEffect(() => {
    if (current_vpn_provider !== undefined) {
      if (lastUsedProviderVpnProvider !== provider) {
        setProviderVpnUsername("");
        setProviderVpnPassword("");
        setProviderVpnServerHostname("");
      } else {
        setProviderVpnUsername(lastUsedProviderVpnUsername);
        setProviderVpnServerHostname(lastUsedProviderVpnServerHostname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const verifyState = (
    serviceKey: AdapterGetServiceType,
    expected: boolean,
    retryCount: number
  ) =>
    createPollAndRefresh<AdapterListItem | undefined>(
      loadAdapter,
      (adapter?: AdapterListItem) => {
        // if (serviceKey === "vpn") {
        //   return (adapterDetails.ip.vpn.ip4 != null) === expected;
        // }
        return adapter?.services[serviceKey]?.state === expected;
      },

      (state: UIServiceState) => updateStatus(serviceKey, state),
      retryCount
    );

  const updateDetails = (service: ConfigServices, state: boolean) => {
    updateStatus(service, "Updating");
    const payload = {
      id: adapterId,
      service,
      state,
    };
    api.adapter
      .changeConfigurationService(payload)
      .then(() => {
        verifyState(service, state, 10);
      })
      .catch(() => setToastMessage("Error updating Router configuration"));
  };
  const isAdapterOffline = useMemo<boolean>(
    () => !adapterDetails?.services.online.state,
    [adapterDetails]
  );
  const isVPNDown = adapterDetails?.services.vpn.state === false;
  const { t } = useTranslation("securitySettings");

  const {
    handleSubmit,
    // formState,
  } = useForm<VPNProviderRequirements>({
    mode: "all",
    defaultValues: {
      country: null,
    },
  });

  // const { isValid, errors } = formState;

  const providerHostnameChangeHandler = (e: any) => {
    const providerHostnameValue = e.target.value;
    const validationState = validationSuiteVpnHostname({
      providerHostname: providerHostnameValue,
    });

    setProviderVpnServerHostnameHasErrors({
      hasError: validationState.hasErrors(),
      errorMessage: validationState.getErrors("providerHostname").shift(),
    });

    vpnConnectionConfigValuesMap[
      selectedProviderCreds.server_hostname
        .field as keyof typeof vpnConnectionConfigValuesMap
    ]?.setValue(providerHostnameValue);
  };

  const onSubmit = () => {
    var vpnConfig = {
      id: adapterId,
      provider: provider,
      country: undefined,
      creds: {
        username: providerVpnUsername,
        password: providerVpnPassword,
        server_hostname: providerVpnServerHostname,
      },
    };
    setConnError(false);
    handleProviderScreenChange("provider_connecting");
    dispatch.vpn
      .configureVPNProvider({
        config: vpnConfig,
        updateUi: setUiStatus,
        provider_name: providerDSPName,
        connection_error: setConnError,
        connection_error_message: `Invalid Credentials or expired subscription. \n Please check your service provider for more details. ${VPNConnStatus?.error}`,
      })
      .then(async () => {
        handleProviderScreenChange("provider_status");
      });
  };

  const onCancel = () => {
    handleProviderScreenChange("provider_status");
    //Set provider name to previously used connection
    if (lastUsedProviderVpnProvider) {
      setProvider(
        VPN_PROVIDERS[lastUsedProviderVpnProvider as keyof typeof VPN_PROVIDERS]
          .value
      );
      setProviderDSPName(
        VPN_PROVIDERS[lastUsedProviderVpnProvider as keyof typeof VPN_PROVIDERS]
          .name
      );
    } else {
      setProvider(VPN_PROVIDERS.phero.value);
      setProviderDSPName(VPN_PROVIDERS.phero.name);
    }
  };

  function setNameWConn() {
    var current_vpn_provider = adapterDetails.settings.vpn;
    var current_connections = adapterDetails.status?.vpn_connections;
    if (current_vpn_provider !== undefined) {
      setProvider(current_vpn_provider[0].provider);
      for (var provider_idx in providers) {
        if (
          providers[provider_idx].provider === current_vpn_provider[0].provider
        ) {
          setProviderDSPName(providers[provider_idx].display_name);
        }
      }
      if (current_connections !== undefined) {
        setVPNConnStatus(current_connections[0]);
      }
    } else {
      setProvider(VPN_PROVIDERS.phero.value);
      setProviderDSPName(VPN_PROVIDERS.phero.name);
    }
  }

  async function handleProviderScreenChange(screen_name: string) {
    var new_element = document.getElementById(providerSelectionState);
    if (new_element !== null) {
      new_element.className =
        new_element.className +
        " transition-opacity duration-700 ease-out opacity-0 ";
      await new Promise((r) => setTimeout(r, 400));
    }
    setSelectionState(screen_name);
    //TODO findme, reset provider_hostname to whatever the status is for the last successfull connection
    // if(currentUsedProviderVpnServerHostname) {
    //   setProviderVpnServerHostname(currentUsedProviderVpnServerHostname)
    // }
    return undefined;
  }
  if (uiStatus === "Failed!" && !state.loading) {
    setNameWConn();
    setUiStatus("Done!");
  }

  return (
    <PageLayout testId="security-settings">
      <div className="module-large">
        <Title title={t("title")} />
        <ul className="data-list pt-3">
          <ListRow
            key="vpn_selection"
            text={t("vpn")}
            status={connError ? "Failed!" : serviceStatus.vpn}
            disabled={isAdapterOffline}
            className="self-center flex flex-col justify-between w-full"
          >
            <div className="flex flex-col justify-between w-full">
              <div className="flex flex-row justify-end w-full h-16 relative">
                {/* VPN Selection Goes Here */}
                {providerSelectionState === "provider_selection" && (
                  <div
                    id="provider_selection"
                    className="flex flex-col justify-around items-start x-40 bg-gray-100 rounded-xl shadow-2xl drop-shadow-lg absolute z-20 px-5 py-4 position-abs-center-horizontally "
                  >
                    <h2 className="mt-2 mb-5 text-left text-xl font-semibold whitespace-nowrap">
                      Change VPN service provider
                    </h2>
                    <div className="flex flex-row justify-start items-center w-full gap-3">
                      <label
                        htmlFor="Provider Selection"
                        className="text-md text-center font-medium my-3 whitespace-nowrap bold text-gray-500"
                      >
                        {t("vpnProviderSelect")}
                      </label>
                      <div className="select-container w-full">
                        <select
                          id="provider"
                          data-testid="provider-select"
                          value={provider}
                          className="w-full mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                          onChange={(e) => {
                            const option_content =
                              e.currentTarget.options[
                                e.currentTarget.selectedIndex
                              ].text;
                            setProvider(e.currentTarget.value);
                            setProviderDSPName(
                              option_content === null ? "" : option_content
                            );
                          }}
                        >
                          {providerData.map((provider) => (
                            <option
                              data-testid={`vpn-${provider.provider}`}
                              key={provider.provider}
                              value={provider.provider}
                            >
                              {provider.display_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/*
                      The "VPN_PROVIDERS.nordvpn.value" checks are a mitigation for
                      https://github.com/privacy-hero/issues/issues/6144
                    */}
                    <div className="flex flex-row justify-start items-center w-full mt-5 gap-2">
                      {provider !== VPN_PROVIDERS.nordvpn.value &&
                        selectedProviderCreds?.username && (
                          <div className="basis-full">
                            <Input
                              id={selectedProviderCreds.username.field}
                              data-testid={selectedProviderCreds.username.field}
                              label={selectedProviderCreds.username.prompt}
                              inputProps={{
                                value:
                                  vpnConnectionConfigValuesMap[
                                    selectedProviderCreds.username
                                      .field as keyof typeof vpnConnectionConfigValuesMap
                                  ]?.value,
                                onChange: (e: any) =>
                                  vpnConnectionConfigValuesMap[
                                    selectedProviderCreds.username
                                      .field as keyof typeof vpnConnectionConfigValuesMap
                                  ]?.setValue(e.target.value),
                              }}
                              error=""
                              type="text"
                              placeholder=""
                              useTextArea={provider === "wireguard"}
                            />
                          </div>
                        )}
                      {selectedProviderCreds?.password && (
                        <div className="basis-full">
                          <PasswordInput
                            inputTagClassname="pr-8"
                            id={selectedProviderCreds.password.field}
                            data-testid={selectedProviderCreds.password.field}
                            label={
                              provider === VPN_PROVIDERS.nordvpn.value
                                ? "Token:"
                                : selectedProviderCreds.password.prompt
                            }
                            inputProps={{
                              autoComplete: "new-password",
                              value:
                                vpnConnectionConfigValuesMap[
                                  selectedProviderCreds.password
                                    .field as keyof typeof vpnConnectionConfigValuesMap
                                ]?.value,
                              onChange: (e: any) =>
                                vpnConnectionConfigValuesMap[
                                  selectedProviderCreds.password
                                    .field as keyof typeof vpnConnectionConfigValuesMap
                                ]?.setValue(e.target.value),
                            }}
                            error=""
                            placeholder=""
                          />
                        </div>
                      )}
                      {/*
                        //TODO this code is not used at the moment, but we may need it in the future, just the layout itself
                        <div className="pl-3 flex flex-col">
                          <label className="pb-1 block text-sm whitespace-nowrap font-medium text-gray-700 capitalize">
                            {req_field.prompt}
                          </label>
                          <select
                            id={req_field.field}
                            data-testid={req_field.field}
                            value={getProviderSelectionValue(
                              req_field.field,
                              providerSelections
                            )}
                            className="block w-fit pl-3 pr-10 px-3 py-3 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                            onChange={(v) => {
                              var newSelections = newProviderSelection(
                                req_field.field,
                                v.currentTarget.value,
                                providerSelections
                              );
                              setProviderSelections(newSelections);
                            }}
                          >
                            {req_field.options.selections.map(
                              (selection) => (
                                <option
                                  data-testid={`vpn-${selection}`}
                                  key={req_field.field + selection}
                                  value={selection}
                                >
                                  {selection}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        */}
                    </div>

                    {provider === VPN_PROVIDERS.nordvpn.value && (
                      <p className="pb-0 mb-0 mt-2 text-black">
                        <a
                          className="text-orange"
                          href="https://support.privacyhero.com/article/139-how-to-use-nordvpn-with-privacy-hero-router"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Where do I get NordVPN token?
                        </a>
                      </p>
                    )}

                    {selectedProviderCredsHasOptionalFields && (
                      <>
                        <div
                          className="flex flex-row flex justify-between items-center w-full mt-5 select-none cursor-pointer"
                          onClick={() => {
                            setProviderConfigAdvancedSectionIsActive(
                              !providerConfigAdvancedSectionIsActive
                            );
                          }}
                        >
                          <h4 className="m-0 p-0 ">
                            Advanced options:
                            <span
                              className={classNames("ml-1 font-semibold", {
                                "is-active text-rose-500": !providerConfigAdvancedSectionIsActive,
                                "text-green-600": providerConfigAdvancedSectionIsActive,
                              })}
                            >
                              {providerConfigAdvancedSectionIsActive
                                ? "On"
                                : "Off"}
                            </span>
                          </h4>
                          <IonIcon
                            icon={chevronDown}
                            className={classNames(
                              providerConfigAdvancedSectionIsActive
                                ? "-rotate-180"
                                : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </div>

                        {providerConfigAdvancedSectionIsActive && (
                          <>
                            {selectedProviderCreds?.server_hostname && (
                              <>
                                <div className="flex flex-row justify-start items-center w-full mt-2 gap-2">
                                  <Input
                                    className="basis-full w-full"
                                    id={
                                      selectedProviderCreds.server_hostname
                                        .field
                                    }
                                    data-testid={
                                      selectedProviderCreds.server_hostname
                                        .field
                                    }
                                    label={
                                      selectedProviderCreds.server_hostname
                                        .prompt
                                    }
                                    inputProps={{
                                      value:
                                        vpnConnectionConfigValuesMap[
                                          selectedProviderCreds.server_hostname
                                            .field as keyof typeof vpnConnectionConfigValuesMap
                                        ]?.value,
                                      onChange: providerHostnameChangeHandler,
                                    }}
                                    error={
                                      providerVpnServerHostnameHasErrors.errorMessage
                                    }
                                    type="text"
                                    placeholder=""
                                  />
                                </div>
                                {provider === VPN_PROVIDERS.nordvpn.value && (
                                  <p className="pb-0 mb-0 mt-2 text-black">
                                    List of servers available at:{" "}
                                    <a
                                      className="text-orange"
                                      href="https://nordvpn.com/servers/tools/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      NordVPN website
                                    </a>
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {!pheroVpn && provider === VPN_PROVIDERS.phero.value && (
                      <div className="flex flex-row justify-center items-center">
                        <p style={{ whiteSpace: "pre-line", width: "80%" }}>
                          {t("pheroVpnMessage")}
                        </p>
                      </div>
                    )}

                    {(pheroVpn || provider !== VPN_PROVIDERS.phero.value) && (
                      <div className="flex flex-row justify-end items-center w-full mt-5">
                        <Button
                          data-testid="submit"
                          className="text-center mr-3"
                          size="small"
                          type="button"
                          disabled={providerVpnServerHostnameHasErrors.hasError}
                          onClick={handleSubmit(onSubmit)}
                        >
                          Save
                        </Button>
                        <Button
                          data-testid="back_select_provider"
                          className="text-center"
                          size="small"
                          type="button"
                          variant="white"
                          disabled={false}
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                    {!pheroVpn && provider === VPN_PROVIDERS.phero.value && (
                      <div className="flex flex-row justify-end items-center w-full mt-5">
                        <Button
                          data-testid="submit"
                          className="text-center mr-3"
                          size="small"
                          type="button"
                          disabled={providerVpnServerHostnameHasErrors.hasError}
                          onClick={() =>
                            history.push(
                              `/account/upgrade/${adapterDetails.id}`
                            )
                          }
                        >
                          Upgrade
                        </Button>
                        <Button
                          data-testid="back_select_provider"
                          className="text-center"
                          size="small"
                          type="button"
                          variant="white"
                          disabled={false}
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                <div
                  id="provider_status"
                  className={
                    providerSelectionState === "provider_status"
                      ? "flex flex-row items-center grow flex-1 justify-center"
                      : "hidden"
                  }
                >
                  <label
                    htmlFor="Provider Selection Name"
                    className="text-md mx-5 py-3 text-center font-medium my-3 bold text-gray-500"
                  >
                    {providerDSPName}
                  </label>
                  <Button
                    data-testid="select_provider"
                    className="text-center"
                    type="button"
                    size="small"
                    disabled={isAdapterOffline}
                    onClick={async () =>
                      handleProviderScreenChange("provider_selection")
                    }
                  >
                    Change
                  </Button>
                </div>
                <div
                  id="provider_connecting"
                  className={
                    providerSelectionState === "provider_connecting"
                      ? "flex flex-row items-center grow flex-1 justify-center"
                      : "hidden"
                  }
                >
                  <label
                    htmlFor="Provider Selection"
                    className="text-md mx-5 py-3 text-center font-medium my-3 rounded-xl bg-white bold text-gray-500"
                  >
                    Connecting To {providerDSPName}
                  </label>
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  >
                    \
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <div className="self-center">
                  <Toggle
                    onChange={(c) => updateDetails("vpn", c)}
                    checked={adapterDetails.ip.vpn.ip4 != null && !isVPNDown}
                    id="vpn-active"
                    disabled={
                      isAdapterOffline ||
                      (isVPNDown && adapterDetails.ip.vpn.ip4 == null)
                    }
                  />
                </div>
              </div>
              {/* VPN Selection Goes Here */}
            </div>
          </ListRow>
          <ListRow
            text={t("vpn_killswitch")}
            status={serviceStatus["vpn-killswitch"]}
            disabled={isAdapterOffline}
          >
            <Toggle
              onChange={(c) => updateDetails("vpn-killswitch", c)}
              checked={adapterDetails.services["vpn-killswitch"].state}
              id="vpn-killswitch"
              disabled={isAdapterOffline}
            />
          </ListRow>
          <ListRow
            key="phishing"
            text={t("malware")}
            status={serviceStatus.malware}
            disabled={isAdapterOffline}
          >
            <Toggle
              onChange={(c) => updateDetails("malware", c)}
              checked={adapterDetails.services.malware.state}
              id="malware-phishing"
              disabled={isAdapterOffline}
            />
          </ListRow>
          <ListRow
            text={t("adBlocking")}
            status={serviceStatus.adblocking}
            disabled={isAdapterOffline}
          >
            <Toggle
              onChange={(c) => updateDetails("adblocking", c)}
              checked={adapterDetails.services.adblocking.state}
              id="adblocking"
              disabled={isAdapterOffline}
            />
          </ListRow>
          {/* <ListRow
            key="upnp"
            text="UPnP"
            status={serviceStatus.upnp}
            disabled={isAdapterOffline}
          >
            <Toggle
              change={(c) => updateDetails("upnp", c)}
              checked={adapterDetails.services.upnp.state}
              id="upnp"
              disabled={isAdapterOffline}
            />
          </ListRow> */}
        </ul>
      </div>

      {/*
      //TODO uncommend when #5819 is fixed
      <p className="mb-4">
        {AppConfig.CHROME ? (
          <NavigationLink
            title={t("chromeExtension")}
            to="https://google.com"
            isOuter
            className="small has-icon"
          />
        ) : AppConfig.SAFARI ? (
          <NavigationLink
            title={t("safariExtension")}
            to="https://google.com"
            isOuter
            className="small has-icon"
          />
        ) : null}
      </p>
      */}

      <IonToast
        color="warning"
        isOpen={!!toastMessage.length}
        onDidDismiss={() => setToastMessage("")}
        message={toastMessage}
        duration={2000}
      />
    </PageLayout>
  );
};

export default Settings;
