
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Routers",
  "subtitle": "Routers you manage",
  "activateNewRouter": "Activate a new Privacy Hero",
  "subscribe": "Subscribe",
  "updatePaymentMethod": "Update Payment Method",
  "updateSubscription": "Update Plan",
  "updateDisabled": "Please wait until your subscription has less than one week of trial time remaining before performing updates",
  "cancelSubscription": "Cancel Subscription",
  "manageSubscriptions": "Manage Subscriptions",
  "online": "Online",
  "offline": "Offline",
  "activated": "Activated",
  "plan": "Plan",
  "trialing": "trialing",
  "free": "Free",
  "renewMonthly": "Renews monthly on",
  "renewYearly": "Renews yearly on",
  "cancel": "Cancels on",
  "updateDisabledVerbose": "You are currently still within your initial trial period. Subscriptions can only be updated inside the web app once you are within one week of your trial end date.\n\n You may contact support to make changes to your account status and plan at any time."
}
