
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Security & Privacy",
  "vpn": "VPN",
  "malware": "Malware & Phishing",
  "adBlocking": "Ad Blocking",
  "vpn_killswitch":"VPN Killswitch",
  "vpnDown": "Our VPN tunnel is down, VPN is off and can't be configured right now",
  "chromeExtension": "Link to Browser Plugin (Chrome)",
  "safariExtension": "Link to Browser Plugin (Safari)",
  "vpnSettings": {
    "validationErrors": {
      "vpnHostname": "Provided address is not a valid hostname"
    }
  },
  "faq": {
    "title": "FAQ",
    "visitFullBase": "Visit Full Knowledge Base"
  },
  "vpnProviderSelect": "Service Provider",
  "connStatus": "Status",
  "pheroVpnMessage": "Your account level does not include Privacy Hero VPN services.\n\n Click the Upgrade button below to view your upgrade options."
}
