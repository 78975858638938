import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as Checkmark } from "../assets/images/svg-icons/checkmark-circle.svg";
import { ReactComponent as Progress } from "../assets/images/svg-icons/progress.svg";
import { ReactComponent as Warning } from "../assets/images/svg-icons/warning-circle.svg";
import { UIServiceState } from "../model";
import { useFirstRender } from "../utils";

interface ContainerProps {
  disabled?: boolean;
  image?: string;
  subInfo?: string | JSX.Element | null;
  text: string | JSX.Element;
  children?: any;
  select?: () => void;
  status?: UIServiceState;
  className?: string;
}

const ListRowComponent: React.FC<ContainerProps> = ({
  disabled,
  image,
  text,
  subInfo,
  children,
  select,
  status,
  className,
}) => {
  const [
    shouldDisplayNotification,
    setShouldDisplayNotification,
  ] = useState<boolean>(false);
  const firstRender = useFirstRender();
  const { statusClass, StatusComponent } = useMemo(() => {
    let statusClass = "";
    let StatusComponent = null;
    if (status === "Failed!") {
      statusClass = "warning text-red-600";
      StatusComponent = <Warning className="text-red-600 fill-current" />;
    } else if (status === "Done!") {
      statusClass = "success text-green-600";
      StatusComponent = <Checkmark className="text-green-600 fill-current" />;
    } else if (status === "Updating") {
      StatusComponent = <Progress className="animated" />;
    }
    return { statusClass, StatusComponent };
  }, [status]);

  useEffect(() => {
    if (firstRender) {
      return;
    }
    setShouldDisplayNotification(true);
    const newStatusState = status;
    if (status === "Done!" || status === "Failed!") {
      setTimeout(() => {
        if (status === newStatusState) {
          setShouldDisplayNotification(false);
        }
      }, 2000);
    }
  }, [status, firstRender]);
  return (
    <li className={`${disabled ? "off" : ""} ${className}`}>
      <div onClick={select} onKeyPress={select}>
        <div className="inner">
          {image ? (
            <div className="image">
              <div className="image-container">
                <img src={image} alt="" />
              </div>
            </div>
          ) : null}
          <div className="ml-2">
            <span className="font-semibold">{text}</span>
            {subInfo ? (
              <span className={`block text-sm font-extralight mt-1 mr-1`}>
                {subInfo}
              </span>
            ) : null}
          </div>
          <div className="value">{children}</div>
        </div>
        {!!status && shouldDisplayNotification ? (
          <div className={`item-notification ${statusClass}`}>
            <span
              className={`${statusClass} bg-white bg-opacity-90 text-xs shadow-sm p-1 rounded-full`}
            >
              {status}
              {StatusComponent}
            </span>
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default ListRowComponent;
