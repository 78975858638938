import React, { useEffect, useState } from "react";
import { Dispatch, RootState } from "../../store";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { classNames } from "utils";
import { Subscription, AdapterListItem } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";

const RoutersContainer: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation("routers");
  const { adapters, subscriptions } = useSelector((state: RootState) => ({
    adapters: state.adapter.list,
    subscriptions: state.adapter.subscriptions,
  }));
  const [trialUpgradeModalOpen, setTrialUpgradeModalOpen] = useState<boolean>(
    false
  );

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "routers" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onManageSubscriptions = async () => {
    let trialingSubs = subscriptions.filter(
      (sub: Subscription) =>
        sub.status === "trialing" &&
        new Date(sub.current_period_end * 1000).getTime() -
          new Date().getTime() >
          1000 * 3600 * 24 * 7
    );
    if (trialingSubs.length > 0) {
      setTrialUpgradeModalOpen(true);
    } else {
      const apiResult = await tryCatch(
        api.subscription.createCustomerPortalSession()
      );

      if (apiResult.error) {
        console.warn(apiResult.error.message);
      } else {
        window.location.href = apiResult.result.data.url;
      }
    }
  };

  return (
    <div className="py-0 px-8 mx-[-32px]">
      <Title
        title={t("title")}
        subtitle={`${adapters.length} ${t("subtitle")}`}
        className="mt-7"
      />
      <div className="flex flex-row justify-end w-full relative">
        {trialUpgradeModalOpen && (
          <div
            id="provider_selection"
            className="flex flex-col justify-around items-start x-40 bg-gray-100 rounded-xl shadow-2xl drop-shadow-lg absolute z-20 px-5 py-4 position-abs-center-horizontally "
          >
            <div style={{ whiteSpace: "pre-line", padding: "10px" }}>
              {t("updateDisabledVerbose")}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                data-testid="updateSubscription"
                size="small"
                onClick={() => {
                  setTrialUpgradeModalOpen(false);
                }}
              >
                {"Ok"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <ul className="list-none pl-0">
        {adapters.map((adapter) => (
          <AdapterRow
            adapter={adapter}
            subscriptions={subscriptions}
            onTrialUpgrade={() => setTrialUpgradeModalOpen(true)}
          />
        ))}
      </ul>
      <NavigationLink
        to="/onboarding/get-connected"
        title={t("activateNewRouter")}
      />
    </div>
  );
};

interface AdapterRowProps {
  adapter: AdapterListItem;
  subscriptions: Subscription[];
  onTrialUpgrade(): void;
}

const AdapterRow: React.FC<AdapterRowProps> = ({
  adapter,
  subscriptions,
  onTrialUpgrade,
}) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const { t } = useTranslation("routers");

  useEffect(() => {
    if (adapter && adapter.id) {
      let adapterSub = subscriptions.find(
        (sub: Subscription) => sub.adapter_id === adapter.id
      );
      if (adapterSub) {
        setSubscription(adapterSub);
      }
    }
  }, [adapter, subscriptions]);

  const onUpdateSubscription = async () => {
    if (subscription) {
      if (
        subscription.status === "trialing" &&
        new Date(subscription.current_period_end * 1000).getTime() -
          new Date().getTime() >
          1000 * 3600 * 24 * 7
      ) {
        onTrialUpgrade();
      } else {
        const apiResult = await tryCatch(
          api.subscription.createCustomerPortalSession_SubscriptionUpdate(
            subscription.subscription_id
          )
        );

        if (apiResult.error) {
          console.warn(apiResult.error.message);
        } else {
          window.location.href = apiResult.result.data.url;
        }
      }
    }
  };

  const onCancelSubscription = async () => {
    if (subscription) {
      const apiResult = await tryCatch(
        api.subscription.createCustomerPortalSession_SubscriptionCancel(
          subscription.subscription_id
        )
      );

      if (apiResult.error) {
        console.warn(apiResult.error.message);
      } else {
        window.location.href = apiResult.result.data.url;
      }
    }
  };

  return (
    <div
      key={`${adapter.name}-${Date.now()}`}
      className="py-3 my-2 rounded border-b-2 border-solid border-gray-100 items-center"
    >
      <div className="text-sm">
        <p className="py-1 font-medium text-gray-900">{adapter.name}</p>
        <div
          className="text-gray-500"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "3px",
              flexDirection: "column",
            }}
          >
            <span>
              <span
                className={classNames(
                  adapter.services.online.state
                    ? "bg-green-100"
                    : "bg-gray-100",
                  "h-4 w-4 rounded-full inline-flex items-center justify-center mr-1"
                )}
                aria-hidden="true"
              >
                <span
                  className={classNames(
                    adapter.services.online.state
                      ? "bg-green-400"
                      : "bg-gray-400",
                    "h-2 w-2 rounded-full"
                  )}
                />
              </span>
              {adapter.services.online.state ? t("online") : t("offline")}
            </span>
            <p className="sm:inline">MAC: {adapter.hw.mac}</p>
            <p className="sm:inline">
              {t("activated")}:{" "}
              {new Date(adapter.registered).toLocaleDateString()}
            </p>
          </div>
          {subscription ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                alignItems: "end",
                flexGrow: 2,
              }}
            >
              <p className="sm:inline">
                {t("plan")}: {subscription.product_name}{" "}
                {subscription.status === "trialing"
                  ? "(" + t("trialing") + ")"
                  : null}
              </p>
              <p className="sm:inline">
                {subscription.cancel_at_period_end
                  ? t("cancel")
                  : subscription.plan_interval === "month"
                  ? t("renewMonthly")
                  : t("renewYearly")}{" "}
                {new Date(
                  subscription.current_period_end * 1000
                ).toLocaleDateString()}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  data-testid="updatePaymentMethod"
                  size="small"
                  onClick={() => {
                    window.location.href = `/account/payment-method/${subscription.subscription_id}`;
                  }}
                >
                  {t("updatePaymentMethod")}
                </Button>
                <Button
                  data-testid="updateSubscription"
                  size="small"
                  onClick={() => {
                    onUpdateSubscription();
                  }}
                >
                  {t("updateSubscription")}
                </Button>
                {!subscription.cancel_at_period_end ? (
                  <Button
                    data-testid="cancelSubscription"
                    size="small"
                    onClick={() => {
                      onCancelSubscription();
                    }}
                  >
                    {t("cancelSubscription")}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                alignItems: "end",
                flexGrow: 2,
              }}
            >
              <p className="sm:inline">
                {t("plan")}: {t("free")}
              </p>
              <Button
                data-testid="subscribe"
                size="small"
                onClick={() => {
                  window.location.href = `/account/subscribe/${adapter.id}`;
                }}
              >
                {t("subscribe")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoutersContainer;
