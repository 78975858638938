import { createModel } from "@rematch/core";
import { RootModel } from "./models";
import api from "../api";
import { VPNProvider, UIServiceState } from "../model";
import { tryCatch } from "../utils";
import { VPNProviderConfigBody } from "../api/vpn";

export interface VPNState {
  adapterVPNProviderMap: VPNProvider[];
}

const initialState: VPNState = {
  adapterVPNProviderMap: [],
};

export const vpn = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setVPNProviders(state, { providers }: { providers: VPNProvider[] }) {
      vpn.state.adapterVPNProviderMap = providers;
      return {
        ...state,
        providers,
      };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getVPNProviders(_, rootState) {
      const res = await api.vpn.getVPNProviders().then((d) => d.data);

      dispatch.vpn._setVPNProviders({
        providers: res,
      });
      return res;
    },
    async configureVPNProvider({
      config,
      updateUi,
      provider_name,
      connection_error,
      connection_error_message = "",
    }: {
      config: VPNProviderConfigBody;
      updateUi: (state: UIServiceState) => any;
      provider_name: string;
      connection_error: (prevState: boolean) => void;
      connection_error_message?: string;
    }) {
      updateUi("Updating");
      const result = await tryCatch(api.vpn.configureVPNProvider(config));
      const errorTitle = `Error configuring ${provider_name}!`;
      const successMessage = `You are now connected with ${provider_name}!`;

      if (result.error) {
        const errorMessage =
          result.error?.response?.data?.detail || connection_error_message;

        updateUi("Failed!");
        connection_error(true);

        dispatch.ui.setToast({
          notificationTitle: errorTitle,
          description: errorMessage,
          type: "error",
        });
      } else {
        connection_error(false);
        updateUi("Done!");

        dispatch.ui.setToast({
          description: successMessage,
          type: "success",
        });
      }
    },
  }),
});
